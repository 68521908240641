.software-guide-page {
  width: 30em;
  margin: 2em auto;
  position: relative;

  .ks-tab {
    padding-top: 1rem;
    .react-tabs {
      &__tab-list {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        width: calc(100% - 170px);
        right: 80px;
      }
    }
  }

  &--video {
    padding: 1.5em 0;
  }

  &--title-text {
    padding-bottom: 1em;
  }

  &--white-space {
    white-space: pre-wrap;
    padding-bottom: 1.7em;
    & > * {
      line-height: 1.5em;
    }
  }

  &--link {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }

  &--manual-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-bottom: 1.7rem;
    justify-content: space-between;
  }

  .button {
    height: 1.7em;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 13px;
    }
  }
}
