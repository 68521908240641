.ks-tab {
  .react-tabs {
    &__tab-list {
      border-bottom: none;
    }

    &__tab {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: #b1b5c3;
      border-bottom: 1px solid #b1b5c3;
      padding: 4px 15px 4px 8px;
      background: transparent;

      &:focus:after {
        content: none;
      }

      &--selected {
        color: $pink;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $pink;
      }
    }

    &__tab-panel {
      animation: fadeIn 1.5s ease-out forwards;
    }
  }

  @media screen and (max-width: 600px) {
    .react-tabs {
      &__tab {
        padding: 4px 0 4px 8px;

        &_list {
          display: flex;
          flex-wrap: nowrap;
        }
      }
    }
  }
}
