@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1 !important;
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 1;
  }
}
